import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class FeaturedVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlaying: false,
      url: 'https://youtu.be/zO7M9Kbj03I',
    }

    /**
     * Video Player
     * @type {ReactPlayer}
     */
    this._player = null
  }

  componentWillUnmount() {
    if (this._player) {
      this._player = null
    }
  }

  playVideo = () => {
    this.setState({ isPlaying: true })
  }

  onEnded = () => {
    this.setState({ isPlaying: false })
  }

  ref = player => {
    this._player = player
  }

  render() {
    const { isPlaying, url } = this.state

    return (
      <section className="featured-video">
        <div className="container-large">
          <h2
            className="eatured-vide__heading
                      visually-hidden"
          >
            Featured Video
          </h2>
          <div
            className={
              'featured-video__player-wrapper ' +
              (!isPlaying ? 'featured-video__player-wrapper--hidden' : '')
            }
          >
            <ReactPlayer
              className="featured-video__player"
              url={url}
              width="100%"
              height="100%"
              ref={this.ref}
              playing={isPlaying}
              onEnded={this.onEnded}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    rel: 0,
                    controls: 2,
                  },
                },
              }}
            />
          </div>

          {!isPlaying ? (
            <div
              id="featued-video-overlay"
              className="featured-video__overlay"
              onClick={() => this.playVideo()}
            >
              <button
                className="featured-video__play-button"
                onClick={() => this.playVideo()}
              >
                <svg
                  width="100"
                  height="100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <g id="play" fill="currentColor" fillRule="nonzero">
                      <path d="M50 0C22.384 0 0 22.384 0 50s22.384 50 50 50 50-22.384 50-50S77.616 0 50 0zm23.925 54.014L44.101 74.532a4.878 4.878 0 0 1-5.03.301 4.888 4.888 0 0 1-2.607-4.315V29.482a4.888 4.888 0 0 1 2.607-4.315 4.878 4.878 0 0 1 5.03.301l29.824 20.518A4.878 4.878 0 0 1 76.035 50c0 1.603-.79 3.106-2.11 4.014z" />
                    </g>
                  </g>
                </svg>
                <span className="visually-hidden">Play Video</span>
              </button>

              <img
                src="https://cdn.emersoft.co/emersoft-website/homepage/emersoft-video-thumbnail-compressed.jpg"
                alt="Emersoft team in New York"
                className="featured-video__img"
              />
            </div>
          ) : null}
        </div>
      </section>
    )
  }
}

export default FeaturedVideo
